/* l10n_client container */
#l10n-client {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: left;
  z-index: 99;
  line-height: 1em;
  color: #000;
  background: #fff;
  position: fixed;
  width: 100%;
  height: 2em;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
}

* html #l10n-client {
  position: static;
}

#l10n-client-string-select .string-list,
#l10n-client-string-editor .source,
#l10n-client-string-editor .editor {
  height: 20em;
}

#l10n-client .labels {
  position: relative;
  height: 2em;
  color: #fff;
  background: #37a;
}

 #l10n-client .labels .lbl {
  display: none;
}

#l10n-client-hide {
  cursor: pointer;
  display: none;
  position: absolute;
  right: 0em;
  padding: 0em .75em;
  height: 2em;
  line-height: 2em;
  text-transform: uppercase;
  text-align: center;
  background: #000;
}

#l10n-client-show {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0em;
  padding: 0em .75em;
  height: 2em;
  line-height: 2em;
  text-transform: uppercase;
  text-align: center;
  background: #000;
}

/* Panel labels */
#l10n-client h2 {
  border-left: 1px solid #fff;
  height: 1em;
  line-height: 1em;
  padding: .5em;
  margin: 0px;
  font-size: 1em;
  text-transform: uppercase;
}

#l10n-client .strings h2 {
  border: 0px;
}

/* 25 + 37 + 38 = 100 */
#l10n-client .strings     { width: 25%; float: left; }
#l10n-client .source      { width: 37%; float: left; }
#l10n-client .translation { width: 38%; float: left; }

/* Translatable string list */
#l10n-client-string-select {
  display: none;
  float: left;
  width: 25%;
}

#l10n-client .string-list {
  height: 20em;
  overflow: auto;
  list-style: none;
  list-style-image: none;
  margin: 0em;
  padding: 0em;
}

#l10n-client .string-list li {
  line-height: 1.5em;
  cursor: default;
  background: transparent;
  list-style: none;
  list-style-image: none;
  border-bottom: 1px solid #ddd;
  padding: .25em .5em;
  margin: 0em;
}

/* Green for translated */
#l10n-client .string-list li.translated {
  border-bottom-color: #9c3;
  background: #cf6;
  color: #360;
}

#l10n-client .string-list li.translated:hover {
  background: #df8;
}

#l10n-client .string-list li.translated:active {
  background: #9c3;
}

/* Gray + Blue hover for untranslated */
#l10n-client .string-list li.untranslated {}

#l10n-client .string-list li.untranslated:hover {
  background: #ace;
}

#l10n-client .string-list li.untranslated:active {
  background: #8ac;
}

/* Selected string is indicated by bold text */
#l10n-client .string-list li.active {
  font-weight: bold;
}

#l10n-client-string-editor {
  display: none;
  float: right;
  width: 75%;
}

#l10n-client-string-editor .source {
  overflow: hidden;
  width: 50%;
  float: left;
}

#l10n-client-string-editor .source .source-text {
  line-height: 1.5em;
  background: #eee;
  height: 16em;
  margin: 1em;
  padding: 1em;
  overflow: auto;
}

#l10n-client-string-editor .translation {}

#l10n-client-form {
  padding: 0em;
}

#l10n-client-form textarea {
  height: 13em;
  font-size: 1em;
  line-height: 1.25em;
  width: 95%;
}
